import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { ToastrService } from 'ngx-toastr';
import { finalize, tap } from 'rxjs/operators';
import { DeviceParamNBK, DeviceService } from 'src/app/api/nbk';
import { DeviceParamNBKExtended, parameterDevice } from 'src/app/core/utils';

@Component({
  selector: 'app-parameter-device',
  templateUrl: './parameter-device.component.html',
  styleUrls: ['./parameter-device.component.scss']
})
export class ParameterDeviceComponent implements OnInit {
  @Input() deviceId: string;
  
  parameterList?: DeviceParamNBKExtended[];
  dataSource = new ClientFiltrableDataSource<DeviceParamNBK>();
  columns: TableColumn[] = [];
  isSavingParams: boolean = false;

  @ViewChild('valueTpl', { static: true }) valueTpl: ElementRef<HTMLElement>;

  constructor(
    private ts: TranslateService,
    private toastrService: ToastrService,
    private deviceService: DeviceService
  ) {
    this.parameterList = parameterDevice;
    this.dataSource.limit = 8;
  }

  ngOnInit(): void {
    this.columns = [
      {
        prop: 'id',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.ID')
      },
      {
        prop: 'name',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.NAME')
      },
      {
        prop: 'value',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.VALUE'),
        cellTemplate: this.valueTpl
      },
      {
        prop: 'min',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.MIN')
      },
      {
        prop: 'max',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.MAX')
      },
      {
        prop: 'defValue',
        name: this.ts.instant('DEVICES.DEVICE_CARD.MODALS.PARAMETER.COLUMN.DEFAULT')
      },
    ];

    this.getParams();
  }

  getParams() {
    this.deviceService
      .getDevicePatams(+this.deviceId!)
      .pipe(
        tap((res) => {
          this.dataSource.setItems([...res]);
        })
      )
      .subscribe();
  }

  saveParams() {
    const params = this.dataSource.items?.map((i) => {
      return { id: i.id, value: i.value };
    });

    this.isSavingParams = true;

    this.deviceService
      .setDevicePatams(+this.deviceId, params)
      .pipe(
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICES.DEVICE_CARD.MODALS.SAVE_PARAMS'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        }),
        finalize(() => (this.isSavingParams = false))
      )
      .subscribe();
  }

}
