<div class="modal-body p-3">
  <div class="row">
    <span *ngIf="counters">
      {{ 'GLOBAL.LAST_UPDATE'| translate: { time: (counters.timestamp! | localizedDate:'short') } }}
    </span>
    <div class="table-action-box">
      <button class="button-primary mx-2" (click)="resetAllCounters()">
        <i class="fa fa-sync-alt"></i> 
        {{ 'GLOBAL.RESET_ALL' | translate }}
      </button>
    </div>
  </div>

  <div class="row py-4 h-100 d-flex align-items-stretch" *ngIf="counters && counters.data; else noData">
    <div class="col-12 col-lg-4 d-flex" *ngFor="let counter of getEntries()">
      <table class="equal-table">
        <tr>
          <td>{{ ('DEVICE.COUNTERS.' + counter[0].toUpperCase()) | translate }}</td>
          <td>{{ counter[1] }}</td>
          <td>
            <button class="button-primary mx-2" (click)="resetCounters(counter[0])">
              <i class="fa fa-sync-alt"></i> 
              {{ 'GLOBAL.RESET' | translate }}
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>

</div>

<ng-template #noData>
  <p>{{ 'GLOBAL.NO_DATA' | translate }}</p>
</ng-template>

