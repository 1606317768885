import { Injectable } from '@angular/core';
import { infoDevice, INFO_DEVICE_STORAGE_KEY } from './device.utils';
import { MykeycloakService } from './mykeycloak.service';
import { UserKeycloakDtoNBK } from '../api/nbk';

export type UserPermissions = {
  [key in UserKeycloakDtoNBK.RolesEnum]: boolean;
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  userId: string
  permissions: UserPermissions;

  constructor(private kc: MykeycloakService) {}

  public evaluate() {
    this.userId = this.kc.user.id!;
    this.permissions = {
      DEFAULT_ROLE: this.kc.roles.includes('DEFAULT_ROLE'),
      CHEF_EXECUTIVE: this.kc.roles.includes('CHEF_EXECUTIVE'),
      CHEF_LAINOX: this.kc.roles.includes('CHEF_LAINOX'),
      USER_MANAGER: this.kc.roles.includes('USER_MANAGER'),
      SUPERUSER: this.kc.roles.includes('SUPERUSER'),
      SERVICE: this.kc.roles.includes('SERVICE'),
      SERVICE_ADMIN: this.kc.roles.includes('SERVICE_ADMIN'),
    };

    if (!localStorage.getItem(INFO_DEVICE_STORAGE_KEY)) {
      const infoDeviceVisible = infoDevice
        .filter((i) => i.defaultValue)
        .map((i) => i.key);
      localStorage.setItem(
        INFO_DEVICE_STORAGE_KEY,
        JSON.stringify(infoDeviceVisible)
      );
    }
  }

}
