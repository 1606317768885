<div class="modal-body p-3">
  <div class="row">
    <div class="col d-flex justify-content-end mb-3">
      <button class="button-primary mx-2" (click)="getParams()">
        <i class="fa fa-sync-alt"></i> 
        {{ 'GLOBAL.UPDATE' | translate }}
      </button>
      <button class="button-primary" (click)="saveParams()">
        <i class="fa fa-save"></i> 
        {{ 'GLOBAL.SAVE' | translate }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-datatable
        [dataSource]="dataSource"
        [columns]="columns"
      ></app-datatable>
    </div>
  </div>
</div>

<ng-template #valueTpl let-row="row">
  <input
    type="number"
    class="custom-input"
    [(ngModel)]="row.value"
    [disabled]="isSavingParams"
  />
</ng-template>

