import { Component, Input, OnInit, } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { DeviceService, InfluxLastCountersDtoNBK, TelemetryService } from 'src/app/api/nbk';
import { getEntries } from 'src/app/core/utils';
import { TwoBtnModalState } from '../../modals/two-btn-modal/two-btn-modal.component';
import { of } from 'rxjs';
import { ModalService } from '../../modals/modal.service';

@Component({
  selector: 'app-counters-device',
  templateUrl: './counters-device.component.html',
  styleUrls: ['./counters-device.component.scss']
})
export class CountersDeviceComponent implements OnInit {
  @Input() deviceSerial: string;
  @Input() deviceId: string;
  
  counters?: InfluxLastCountersDtoNBK;

  constructor(
    private ts: TranslateService,
    private toastrService: ToastrService,
    private deviceService: DeviceService,
    private telemetryService: TelemetryService,
    private modalButtonService: ModalService
  ) { }

  ngOnInit(): void {


    this.getCounters();
  }

  getCounters() {
    this.telemetryService
      .getLastCounters(this.deviceSerial)
      .pipe(
        tap((res) => {
          this.counters = res;
        })
      )
      .subscribe();
  }

  resetCounters(counterName?: string) {
    this.deviceService
      .resetDeviceCounters(+this.deviceId, counterName ? [counterName] : [])
      .pipe(
        tap(() => {
          this.toastrService.success(
            this.ts.instant('DEVICES.DEVICE_CARD.MODALS.RESET_COUNTER'),
            this.ts.instant('GLOBAL.SUCCESS')
          );
        })
      )
      .subscribe();
  }

  resetAllCounters() {
    const modalInfo: TwoBtnModalState = {
      title: this.ts.instant('DEVICE.COUNTERS.RESET_ALL_TITLE'),
      message: this.ts.instant('DEVICE.COUNTERS.RESET_ALL_MESSAGE'),
      confirmBtnClass: 'button-danger',
      confirmBtnText: this.ts.instant('INGREDIENT.MODAL.DELETE_CONFIRM'),
      cancelBtnClass: 'button-secondary',
      cancelBtnText: this.ts.instant('INGREDIENT.MODAL.DELETE_ABORT'),
      confirm$: of({})
    };

    this.modalButtonService.showTwoBtnModal(modalInfo).subscribe((r: any) => {
      if (r) {
        this.resetCounters();
      }
    });
  }

  getEntries() {
    return getEntries(this.counters?.data!);
  }

}
