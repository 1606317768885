import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TableColumn } from '@swimlane/ngx-datatable';
import { ClientFiltrableDataSource } from 'filtrable-data-source';
import { tap } from 'rxjs/operators';
import { AlarmEventNBK, DeviceBoDtoNBK, TelemetryService } from 'src/app/api/nbk';
import { LocalizedDatePipe } from '../../pipes/localized-date.pipe';

@Component({
  selector: 'app-online-device',
  templateUrl: './online-device.component.html',
  styleUrls: ['./online-device.component.scss']
})
export class OnlineDeviceComponent implements OnInit {
  @Input() device: DeviceBoDtoNBK;
  
  constructor( ) {  }

  ngOnInit(): void { }


}
