<div class="row filter-box pb-xl-4 " [formGroup]="deviceFilterForm">
  <div class="col-12 col-xl-10">
    <div class="row">
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'DEVICE.FILTERS.NAME.LABEL' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="name"
            [placeholder]="'DEVICE.FILTERS.NAME.PLACEHOLDER' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'DEVICE.FILTERS.SERIAL.LABEL' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="serial"
            [placeholder]="'DEVICE.FILTERS.SERIAL.PLACEHOLDER' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'RECIPE.DEVICE_MODEL' | translate }}
          <ng-select
            [items]="deviceModelList"
            formControlName="deviceModel"
            classList="custom-input filter"
            bindValue="id"
            bindLabel="name"
            [clearable]="false"
            [searchable]="false"
            [placeholder]="'RECIPE.DEVICE_MODEL' | translate"
            [readonly]="true"
          >
          <ng-template ng-option-tmp ng-label-tmp let-item="item">
            {{ item.name | translate }}
          </ng-template>
        </ng-select>
        </label>
      </div>
      <div *ngIf="forServiceUser" class="col-12 col-xl-3 pt-3">
        <label class="custom-label w-100">
          {{ 'GLOBAL.LOGGED_USER' | translate }}
          <input
            type="text"
            class="custom-input"
            formControlName="owner"
            [placeholder]="'GLOBAL.LOGGED_USER' | translate"
          />
        </label>
      </div>
      <div class="col-12 col-xl-3 pt-3 align-content-center">
        <label class="custom-label w-100">
          <input type="checkbox" formControlName="online" />
          {{ 'DEVICE.FILTERS.ONLINE' | translate }}
        </label>
      </div>
    </div>
  </div>

  <div class="col-12 col-xl-2">
    <div class="filter-button-box">
      <button
        class="button-primary mb-2"
        (click)="search()"
        [disabled]="!deviceFilterForm.valid"
      >
        <i class="fa fa-fw fa-search"></i>{{ 'GLOBAL.FIND' | translate }}
      </button>
      <button class="button-secondary mb-2" (click)="clean()">
        <i class="fa fa-fw fa-undo"></i>{{ 'GLOBAL.CLEAN' | translate }}
      </button>
    </div>
  </div>
</div>

